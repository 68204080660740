import React, { useEffect, useState } from "react";
import "./Calendar.scss";
import dayjs from "dayjs";
import ModalAddPointInLayer from "../../../components/ModalAddPointInLayer/ModalAddPointInLayer";
import ModalDeleteItem from "../../../components/ModalDeleteItem/ModalDeleteItem";
import SearchPanel from "../../../components/SearchPanel/SearchPanel";
import CalendarMini from "../../../components/CalendarMini/CalendarMini";
import CalendarTable from "../../../components/CalendarTable/CalendarTable";
import ModalEditEvent from "../../../components/ModalEditEvent/ModalEditEvent";
import ModalEventList from "../../../components/ModalEventList/ModalEventList";
import ModalInfoEvent from "../../../components/ModalInfoEvent/ModalInfoEvent";
import AdditionalFilterPanel from "../../../components/AdditionalFilterPanel/AdditionalFilterPanel";
import Filter from "../../../components/Filter/Filter";
import { useModal } from "../../../hooks/useModal";
import ModalTask from "../../../components/ModalTask/ModalTask";
import ModalEditTask from "../../../components/ModalEditTask/ModalEditTask";
import { useDispatch } from "react-redux";
import { asyncDeleteTask } from "../../../store/reducers/tasksReducer";
import { useSelector } from "react-redux";
import {
  asyncDeleteEvent,
  asyncDeleteProtocol,
  asyncDeleteProtocolFromEvent,
  asyncDeleteTaskFromProtocol,
  asyncGetEvents,
} from "../../../store/reducers/calendarReducer";
import ModalParticipant from "../../../components/ModalParticipants/ModalParticipant";
import ModalProject from "../../../components/ModalProject/ModalProject";
import ModalInfoUser from "../../../components/ModalInfoUser/ModalInfoUser";
import ModalAddDellEmployees from "../../../components/ModalAddDellEmployees/ModalAddDellEmployees";
import { useSearch } from "../../../hooks/useSearch";
import ModalSwiperPhoto from "../../../components/ModalSwiperPhoto/ModalSwiperPhoto";
import ModalEditProtocol from "../../../components/ModalEditProtocol/ModalEditProtocol";
import { ErrorBoundary } from "../../../ui";

function Calendar() {
  const dispatch = useDispatch();
  const { changeModalVisible, getVisible, getInfo } = useModal();

  const { search, queries, setQueryParam } = useSearch("calendar");

  const dataEvent = useSelector((state) => state.calendar);

  const [events, setEvents] = useState([]);
  const [count, setCount] = useState(0);

  const [currentDate, setCurrentDate] = useState({
    year: dayjs(new Date()).year(),
    month: dayjs(new Date()).month(),
  });

  const [viewCards, setViewCards] = useState("columns");

  const getEvents = () => search();

  const changeDate = (event, data) => {
    let date = new Date();

    const currentMonth = currentDate.month;
    const currentYear = currentDate.year;

    if (event === "set") {
      const { field, value } = data;

      if (field === "year") {
        date = dayjs().year(value).month(currentMonth).toDate();
      } else if (field === "month") {
        date = dayjs().year(currentYear).month(value).toDate();
      }
    }

    if (event === "next") {
      if (currentMonth >= 11) {
        date = dayjs()
          .year(currentYear + 1)
          .month(0)
          .toDate();
      } else {
        date = dayjs()
          .year(currentYear)
          .month(currentMonth + 1)
          .toDate();
      }
    } else if (event === "prev") {
      if (currentMonth <= 1) {
        date = dayjs()
          .year(currentYear - 1)
          .month(11)
          .toDate();
      } else {
        date = dayjs()
          .year(currentYear)
          .month(currentMonth - 1)
          .toDate();
      }
    }

    setQueryParam({ date });
  };

  const changeViewCard = (value) => setViewCards(value);

  const deleteTask = async () => {
    const { _id: taskId, mode, task } = getInfo("deleteTask", "data");

    if ((mode === "protocol.add" || mode === "protocol.edit") && task === "true") {
      const protocolId =
        getInfo("editEvent", "data")?.protocol?._id ||
        getInfo("addEvent", "data")?.protocol?._id ||
        getInfo("editProtocol", "data")?._id;

      if (protocolId) await dispatch(asyncDeleteTaskFromProtocol({ taskId, protocolId }));
    }

    changeModalVisible("deleteTask", false, "prev");
  };

  const deleteSubTask = async () => changeModalVisible("deleteSubTask", false, "prev");

  const deleteTasks = async () => {
    let idArray = [];

    idArray.push(getInfo("deleteSubTask", "data"));

    await dispatch(asyncDeleteTask({ tasks: idArray }));

    changeModalVisible("deleteSubTask", false);
  };

  const deleteEvent = async () => {
    const { _id: eventId } = getInfo("deleteEvent", "data");

    await dispatch(asyncDeleteEvent(eventId));

    await getEvents();

    changeModalVisible("deleteEvent", false, "prev");
  };

  const deleteProtocol = async () => {
    const protocol = getInfo("deleteProtocol", "data");

    if (protocol) {
      const res = await dispatch(
        asyncDeleteProtocolFromEvent({ protocolId: protocol._id, eventId: protocol.eventId }),
      );

      if (res.error) {
        return changeModalVisible("deleteProtocol", false, null);
      }
    }

    changeModalVisible("deleteProtocol", false, "prev");
  };

  useEffect(() => {
    setEvents(dataEvent.events);
    setCount(dataEvent.count);
  }, [dataEvent.events]);

  useEffect(() => {
    if (queries.date) {
      const date = dayjs(queries.date);
      setCurrentDate({
        year: date.year(),
        month: date.month(),
      });
    } else {
      const date = dayjs().year(currentDate.year).month(currentDate.month).toDate();
      setQueryParam({ date });
    }
  }, [queries.date]);

  return (
    <ErrorBoundary>
      <div className="calendar">
        {getVisible() && <div className="bgc-shadow"></div>}
        <div className="calendar-content">
          <div className="calendar-content__filter-set">
            <AdditionalFilterPanel
              currentPage="calendar"
              viewCards={viewCards}
              onViewClick={changeViewCard}
            />
            <Filter disabled={true} />
          </div>
          <div className="calendar-content-searchPanel">
            <SearchPanel
              buttons={{
                list: [
                  {
                    title: "Мероприятие",
                    icon: true,
                    onClick: () => changeModalVisible("addEvent", true),
                  },
                ],
              }}
            />
          </div>
          <div className="calendar-content-main customScroll">
            <div className="calendar-content-events">
              <CalendarTable
                events={events}
                changeDate={changeDate}
                currentDate={currentDate}
                viewCards={viewCards}
              />
              <CalendarMini events={events} changeDate={changeDate} currentDate={currentDate} />
            </div>
          </div>
        </div>

        {/* радактирование протокола */}
        {getVisible("editProtocol") && <ModalEditProtocol modal />}

        {/* радактирование протокола */}
        {getVisible("addProtocol") && <ModalEditProtocol modal mode="add" />}

        {/* Удалить протокол */}
        {getVisible("deleteProtocol") && (
          <ModalDeleteItem
            changeModalVisible={() => changeModalVisible("deleteProtocol")}
            title="Вы уверены, что хотите удалить этот протокол?"
            headerTitle="Удалить протокол"
            onDelete={deleteProtocol}
          />
        )}

        {/* Добавить мероприятие */}
        {getVisible("addEvent") && <ModalEditEvent mode="add" handleChange={() => getEvents()} />}

        {/* Редактировать мероприятие */}
        {getVisible("editEvent") && <ModalEditEvent mode="edit" handleChange={() => getEvents()} />}

        {/* Список мероприятий */}
        {getVisible("eventList") && <ModalEventList />}

        {/* Информация/редактирование мероприятия */}
        {getVisible("infoEvent") && <ModalInfoEvent />}

        {/* Удалить мероприятие */}
        {getVisible("deleteEvent") && (
          <ModalDeleteItem
            changeModalVisible={() => changeModalVisible("deleteEvent")}
            title="Вы уверены, что хотите удалить это мероприятие?"
            headerTitle="Удалить мероприятие"
            onDelete={deleteEvent}
          />
        )}

        {/* информация о сотруднике */}
        {getVisible("infoParticipant") && <ModalParticipant edit={false} />}

        {/* Информация о проекте */}
        {getVisible("infoProject") && <ModalProject />}

        {/* Информация задачи */}
        {getVisible("infoTask") && <ModalTask restrictSelection />}

        {/* Добавление задачи */}
        {getVisible("addTask") && <ModalEditTask mode={"add"} />}

        {/* Добавить рисунок или поинт */}
        {getVisible("addPoint") && <ModalAddPointInLayer />}

        {/* Редактирование задачи */}
        {getVisible("editTask") && <ModalEditTask saveData mode={"edit"} />}

        {/* Добавление подзадачи */}
        {getVisible("addSubTask") && <ModalEditTask saveData mode={"subTask"} />}

        {/* Редактирование подзадачи при добавлении */}
        {getVisible("editSubTask") && (
          <ModalEditTask
            // getTasks={() => getTasks(pageNumber)}
            saveData
            mode={"editSubTask"}
          />
        )}

        {/* Удалить задачу */}
        {getVisible("deleteTask") && (
          <ModalDeleteItem
            changeModalVisible={() => changeModalVisible("deleteTask", false)}
            onDelete={deleteTask}
            title="Вы уверены, что хотите удалить эту задачу?"
            headerTitle="Удалить задачу"
          />
        )}

        {/* Удалить подзадачу задачи */}
        {getVisible("deleteSubTask") && (
          <ModalDeleteItem
            changeModalVisible={() => changeModalVisible("deleteSubTask", false)}
            onDelete={deleteSubTask}
            title="Вы уверены, что хотите удалить эту подзадачу?"
            headerTitle="Удалить подзадачу"
          />
        )}
        {/* Информация о юзере */}
        {getVisible("infoUser") && <ModalInfoUser />}
        {/* Добалвение\удаление юзеров в задачи */}
        {getVisible("addEmployeesTask") && <ModalAddDellEmployees add={true} />}
        {getVisible("delEmployeesTask") && <ModalAddDellEmployees />}

        {/* Удалить задачи */}
        {getVisible("deleteTasks") && (
          <ModalDeleteItem
            changeModalVisible={() => changeModalVisible("deleteTask", false)}
            onDelete={deleteTasks}
            title="Вы уверены, что хотите удалить эти задачи?"
            headerTitle="Удалить задачу"
          />
        )}
        {getVisible("swiperPhoto") && <ModalSwiperPhoto />}
      </div>
    </ErrorBoundary>
  );
}

export default Calendar;

import React, { useState } from "react";
import "./Participants.scss";
import CardColumnPartic from "./components/CardColumnPartic/CardColumnPartic";
import CardRowsPartic from "./components/CardRowsPartic/CardRowsPartic";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import Pagination from "../../../../../components/Pagination/Pagination";
import ModalParticipant from "../../../../../components/ModalParticipants/ModalParticipant";
import EmptySection from "../../../../../components/EmptySection/EmptySection";
import ModalProject from "../../../../../components/ModalProject/ModalProject";
import ModalAddPartic from "./components/ModalAddPartic/ModalAddPartic";
import { useSelects } from "../../../../../hooks/useSelects";
import { useSelector } from "react-redux";
import ModalDeleteItem from "../../../../../components/ModalDeleteItem/ModalDeleteItem";
import { useModal } from "../../../../../hooks/useModal";
import SearchPanel from "../../../../../components/SearchPanel/SearchPanel";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useFilter } from "../../../../../hooks/useFilter";
import { asyncDeleteEmployeesFromProject } from "../../../../../store/reducers/projectsReducer";
import WrapperLoader from "../../../../../components/WrapperLoader/WrapperLoader";
import { useSearch } from "../../../../../hooks/useSearch";
import { useHasPermissions } from "../../../../../hooks/useHasPermissions";
import { getTypePages } from "../../../../../utils/getTypePages";

function Participants(props) {
  const { viewChapterParticipants } = props;

  const dispatch = useDispatch();

  const projects = useSelector((state) => state.projects);
  const { projectEmployees, project } = projects;

  const [participants, setParticipants] = useState([]);
  const [count, setCount] = useState(0);

  const { selectItems, addItemSelect, addAllItemSelect, clearItems } = useSelects(participants);

  const { getVisible, getInfo, changeModalVisible } = useModal();

  const { setQueryParam, queries, search } = useSearch("project:participants");
  const { visible, types = [] } = useHasPermissions("project:participants", project);

  const deleteFromProject = async () => {
    let employees_for_delete = [];
    if (!!selectItems.length) {
      employees_for_delete = selectItems;
    } else {
      employees_for_delete.push(getInfo("deleteParticipant", "data")._id);
    }

    await dispatch(asyncDeleteEmployeesFromProject(employees_for_delete));

    changeModalVisible("deleteParticipant");
    clearItems();
    search();
  };

  useEffect(() => {
    setParticipants(projectEmployees?.users || []);
    setCount(projectEmployees?.count || 0);
  }, [projectEmployees]);

  return (
    <>
      {getVisible() && <div className="bgc-shadow"></div>}
      <div className="participants">
        <div className="participants-searchPanel">
          <SearchPanel
            navigationLeft={{
              visible: true,
            }}
            inputSearch={{
              visible: true,
              placeholder: "Найти участника проекта по ФИО",
            }}
            buttons={{
              list: [
                {
                  title: "Участник",
                  onClick: () => changeModalVisible("addParticipant"),
                  icon: true,
                },
              ],
            }}
            typePages={getTypePages(types)}
          />
        </div>
        <WrapperLoader
          loading={projects.loading}
          viewCards={viewChapterParticipants}
          chapter={"participants"}
        >
          {participants?.length ? (
            <>
              {viewChapterParticipants === "columns" ? (
                <div className="participants-cards">
                  <div className="participants-cards-content-columns">
                    {participants?.map((participant) => (
                      <CardColumnPartic
                        changeModalVisible={changeModalVisible}
                        key={participant._id}
                        info={participant}
                      />
                    ))}
                  </div>
                  <Pagination count={count} />
                </div>
              ) : (
                <div className="participants-cards rows">
                  <div className="participants-cards-header">
                    {/* <div className="participants-cards-header-item number">
                      <Checkbox onCheck={addAllItemSelect} check={selectItems.length === participants.length} />
                      <span>№:</span>
                    </div> */}
                    <div className="participants-cards-header-item name">
                      <span>Имя:</span>
                    </div>
                    <div className="participants-cards-header-item email">
                      <span>Email:</span>
                    </div>
                    <div className="participants-cards-header-item phone">
                      <span>Телефон:</span>
                    </div>
                    <div className="participants-cards-header-item position">
                      <span>Должность:</span>
                    </div>
                    <div className="participants-cards-header-item company-item">
                      <span>Компания:</span>
                    </div>
                    {/* <div className="participants-cards-header-item role">
                      <span>Роль:</span>
                    </div> */}
                    <div className="participants-cards-header-item-btns"></div>
                  </div>
                  <div className="participants-cards-content rows">
                    {participants.map((participant, i) => (
                      <CardRowsPartic
                        changeModalVisible={changeModalVisible}
                        check={selectItems.includes(participant._id)}
                        addItemSelect={addItemSelect}
                        key={participant._id}
                        info={participant}
                        // number={i + 1 + participants.length * numberPage - participants.length}
                        showCheckbox
                      />
                    ))}
                  </div>
                  <Pagination count={count} />
                </div>
              )}
            </>
          ) : (
            <EmptySection
              text={"В данном проекте ещё нет участников"}
              textBtn={"Добавить"}
              onClick={() => {
                changeModalVisible("addParticipant", true);
              }}
            />
          )}
        </WrapperLoader>
        {/* Информация об участнике */}
        {getVisible("infoParticipant") && (
          <ModalParticipant
            info={getInfo("infoParticipant", "data")}
            edit={getInfo("infoParticipant", "editMode")}
            changeModalVisible={changeModalVisible}
          />
        )}

        {/* Добавить участника */}
        {getVisible("addParticipant") && <ModalAddPartic />}
        {/* Удалить участника */}
        {getVisible("deleteParticipant") && (
          <ModalDeleteItem
            headerTitle="Удалить участника"
            title="Вы уверены, что хотите удалить участника из проекта?"
            modalName="deleteParticipant"
            changeModalVisible={() => changeModalVisible("deleteParticipant")}
            onDelete={deleteFromProject}
          />
        )}
      </div>
    </>
  );
}

export default Participants;

import React, { useEffect, useState } from "react";
import LeftInfo from "../../../components/LeftInfo/LeftInfo";
import SearchPanel from "../../../components/SearchPanel/SearchPanel";
import ModalDeleteItem from "../../../components/ModalDeleteItem/ModalDeleteItem";
import "./Chat.scss";
import ChatList from "../../../components/ChatList/ChatList";
import WindowChat from "../../../components/WindowChat/WindowChat";
import ModalForward from "../../../components/ModalForward/ModalForward";
import ModalAddChat from "../../../components/ModalAddChat/ModalAddChat";
import ModalAddInChat from "../../../components/ModalAddInChat/ModalAddInChat";
import ModalInfoUser from "../../../components/ModalInfoUser/ModalInfoUser";
import { useModal } from "../../../hooks/useModal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  asyncClearСhat,
  asyncCreateMessage,
  asyncDeleteСhat,
  asyncGetChat,
  asyncLeaveСhat,
} from "../../../store/reducers/chatReduser";
import { useSearch } from "../../../hooks/useSearch";
import ModalSwiperPhoto from "../../../components/ModalSwiperPhoto/ModalSwiperPhoto";
import { ErrorBoundary } from "../../../ui";

const Chat = () => {
  const dispatch = useDispatch();

  const { count } = useSelector((state) => state.chats);

  const [currentChat, setCurrentChat] = useState(null);

  const { getVisible, changeModalVisible, getInfo } = useModal();

  const { search, params } = useSearch("chat");

  const getChats = () => {
    search();
  };

  const changeCurrentChat = async (chatId) => {
    if (chatId === null) return setCurrentChat(null);

    const res = await dispatch(asyncGetChat(chatId));

    if (res.error) return;
    setCurrentChat(res.payload);
  };

  const deleteChat = async () => {
    const { _id: chatId } = getInfo("deleteChat", "data");
    if (!chatId) return;
    const res = await dispatch(asyncDeleteСhat(chatId));
    if (!res.error) {
      await getChats();
      changeModalVisible("deleteChat", false);
      setCurrentChat(null);
    }
  };

  const clearChat = async () => {
    const { _id: chatId } = getInfo("clearChat", "data");
    if (!chatId) return;
    const res = await dispatch(asyncClearСhat(chatId));
    if (!res.error) {
      await getChats();
      changeModalVisible("clearChat", false);
      setCurrentChat(null);
    }
  };

  const leaveChat = async () => {
    const { _id: chatId } = getInfo("leaveChat", "data");
    if (!chatId) return;
    const res = await dispatch(asyncLeaveСhat({ chatId }));
    await dispatch(
      asyncCreateMessage({
        chatId,
        data: {
          text: `Покинул группу`,
          event: true,
        },
      }),
    );
    if (!res.error) {
      await getChats();
      changeModalVisible("leaveChat", false);
      setCurrentChat(null);
    }
  };

  useEffect(() => {
    if (!params.chatId) return;

    changeCurrentChat(params.chatId);
  }, [params.chatId]);

  useEffect(() => {
    getChats();
  }, []);
  return (
    <ErrorBoundary>
      <div className="chat">
        {getVisible() && <div className="bgc-shadow"></div>}
        <div className="chat-content">
          <div className="chat-content-filter">
            <LeftInfo title={`Всего чатов: ${count}`} />
            {/* <div className="chat-content-filter-clear">
            <img src={clearIcon} alt="clear" />
          </div> */}
          </div>
          <div className="chat-content-searchPanel">
            <SearchPanel
              inputSearch={{
                visible: true,
                placeholder: "Найти по Ф.И.О сотрудника или названию группы",
              }}
              buttons={{
                list: [
                  {
                    title: "Чат",
                    icon: true,
                    onClick: () => changeModalVisible("addChat"),
                  },
                ],
              }}
            />
          </div>
          <div className="chat-content-main customScroll">
            <div className="chat-content-chatZone">
              <ChatList currentChat={currentChat} />
              <WindowChat currentChat={currentChat} />
            </div>
          </div>
        </div>
        {/* Модальное окно для пересылания сообщение */}
        {getVisible("forward") && <ModalForward handleChange={getChats} />}

        {/* Модальное окно для создания чата */}
        {getVisible("addChat") && <ModalAddChat handleChange={getChats} />}

        {/* Модальное окно для добавления участника */}
        {getVisible("addInChat") && <ModalAddInChat handleChange={getChats} />}

        {/* Модальное окно для удаление чата */}
        {getVisible("deleteChat") && (
          <ModalDeleteItem
            headerTitle="Удаление чата"
            title="Вы уверены, что хотите удалить этот чат?"
            changeModalVisible={() => changeModalVisible("deleteChat")}
            onDelete={deleteChat}
          />
        )}

        {/* Модальное окно для очистки чата */}
        {getVisible("clearChat") && (
          <ModalDeleteItem
            headerTitle="Очистка чата"
            title="Вы уверены, что хотите очистить этот чат?"
            changeModalVisible={() => changeModalVisible("clearChat")}
            onDelete={clearChat}
          />
        )}

        {/* Модальное окно для того чтобы покинуть чат */}
        {getVisible("leaveChat") && (
          <ModalDeleteItem
            headerTitle="Покинуть чат"
            title="Вы уверены, что хотите покинуть этот чат?"
            changeModalVisible={() => changeModalVisible("leaveChat")}
            onDelete={leaveChat}
            onDeleteTitle="Покинуть"
          />
        )}
        {/* Информация о юзере */}
        {getVisible("infoUser") && <ModalInfoUser />}

        {getVisible("swiperPhoto") && <ModalSwiperPhoto />}
      </div>
    </ErrorBoundary>
  );
};

export default Chat;

// * поиск чата по названию
// TODO добавление пользователя в групповой чат
// ? строка, что ниже непрочитанные сообщения

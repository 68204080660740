import React, { useEffect } from "react";
import "./Companies.scss";
import SearchPanel from "../../../../../components/SearchPanel/SearchPanel";
import { useSelects } from "../../../../../hooks/useSelects";
import { useSelector } from "react-redux";
import CompaniesRow from "./components/CompaniesRow/CompaniesRow";
import Pagination from "../../../../../components/Pagination/Pagination";
import CompaniesGridCard from "../../../../../components/CompaniesGridCard/CompaniesGridCard";
import EmptySection from "../../../../../components/EmptySection/EmptySection";
import WrapperLoader from "../../../../../components/WrapperLoader/WrapperLoader";
import { getLimitElements } from "../../../../../utils/getLimitElements";
import { useModal } from "../../../../../hooks/useModal";
import { useSearch } from "../../../../../hooks/useSearch";
import { useHasPermissions } from "../../../../../hooks/useHasPermissions";

const Companies = ({ viewCards, setCompanyLength }) => {
  const { changeModalVisible } = useModal();
  const { add } = useHasPermissions("employees:companies");

  const { params } = useSearch("employees:companies");

  const pageNumber = params.page;

  const { companies, count, loading } = useSelector((state) => state.companies);

  const { selectItems, addItemSelect, addAllItemSelect } = useSelects(companies);

  const getNumber = (i) => i + 1 + companies.length * pageNumber - companies.length;

  useEffect(() => {
    setCompanyLength(count);
  }, [companies]);

  return (
    <section className="companies">
      <div className="companies__searchPanel companies-searchPanel">
        <SearchPanel
          inputSearch={{
            visible: true,
            placeholder: "Найти по названию компанию",
          }}
          buttons={{
            list: add && [
              {
                title: "Компанию",
                icon: true,
                onClick: () => {
                  changeModalVisible("addCompany", true);
                },
              },
            ],
          }}
        />
      </div>
      <div className="companies-content">
        <WrapperLoader loading={loading} chapter={"companies"} viewCards={viewCards}>
          {companies.length > 0 ? (
            viewCards === "rows" ? (
              <div className="companies__row companies-row customScroll">
                <ul className="companies-row__header companies-row-header">
                  {/* <li className="companies-row-header__item numder-item">
                    <Checkbox check={selectItems.length === companies.length} onCheck={addAllItemSelect} />
                    <span>№:</span>
                  </li> */}
                  <li className="companies-row-header__item companies-row-header__item-сompany">
                    <span title="Название компании:">Название компании:</span>
                  </li>
                  <li className="companies-row-header__item companies-row-header__item-manager">
                    <span title="ФИО руководителя:">ФИО руководителя:</span>
                  </li>
                  <li className="companies-row-header__item companies-row-header__item-region">
                    <span title="Город">Город:</span>
                  </li>
                  <li className="companies-row-header__item companies-row-header__item-email">
                    <span title="Email">Email:</span>
                  </li>
                  <li className="companies-row-header__item companies-row-header__item-tel">
                    <span title="Телефон">Телефон:</span>
                  </li>
                  <li className="companies-row-header__item companies-row-header__item-numberEmployees">
                    <span title="Кол-во сотрудников:">Cотрудников:</span>
                  </li>
                  <li className="companies-row-header__item companies-row-header__item-type">
                    <span title="Тип">Тип:</span>
                  </li>
                </ul>
                <ul className="companies-row__list companies-row-list">
                  {companies.map((company, index) => (
                    <CompaniesRow
                      key={company._id}
                      info={company}
                      changeModalVisible={changeModalVisible}
                      number={getNumber(index)}
                      onCheck={() => {
                        addItemSelect(company._id);
                      }}
                      check={selectItems.includes(company._id)}
                    />
                  ))}
                </ul>
                {count > getLimitElements() && (
                  <div className="companies__pagination companies-pagination">
                    <Pagination count={count} />
                  </div>
                )}
              </div>
            ) : (
              <div className="companies__grid companies-grid customScroll">
                <ul className="companies-grid__list companies-grid-list">
                  {companies.map((company, index) => (
                    <CompaniesGridCard
                      key={company._id}
                      info={company}
                      number={getNumber(index)}
                      changeModalVisible={changeModalVisible}
                    />
                  ))}
                </ul>
                {count > getLimitElements() && (
                  <div className="companies__pagination companies-pagination">
                    <Pagination count={count} />
                  </div>
                )}
              </div>
            )
          ) : (
            !loading && (
              <EmptySection
                text={"Компании не добавлены"}
                textBtn={"Добавить"}
                onClick={() => {
                  changeModalVisible("addCompany", true);
                }}
              />
            )
          )}
        </WrapperLoader>
      </div>
    </section>
  );
};
export default Companies;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdditionalFilterPanel from "../../../components/AdditionalFilterPanel/AdditionalFilterPanel";
import Filter from "../../../components/Filter/Filter";
import LeftInfo from "../../../components/LeftInfo/LeftInfo";
import {
  setProject,
  asyncGetProtocols,
  asyncGetScheduleProductionWorks,
  asyncGetProject,
} from "../../../store/reducers/projectsReducer";
import BasicInformation from "./components/BasicInformation/BasicInformation";
import Companies from "./components/Companies/Companies";
import DocumentationProject from "./components/DocumentationProject/DocumentationProject";
import Layers from "./components/Layers/Layers";
import MeetingMinutes from "./components/MeetingMinutes/MeetingMinutes";
import Participants from "./components/Participants/Participants";
import PhotoReports from "./components/PhotoReports/PhotoReports";
import ProjectWorkSchedule from "./components/ProjectWorkSchedule/ProjectWorkSchedule";
import "./Project.scss";
import { useFilter } from "../../../hooks/useFilter";
import { useModal } from "../../../hooks/useModal";
import ModalProject from "../../../components/ModalProject/ModalProject";
import ModalInfoUser from "../../../components/ModalInfoUser/ModalInfoUser";
import ModalAddDellEmployees from "../../../components/ModalAddDellEmployees/ModalAddDellEmployees";
import { useSearch } from "../../../hooks/useSearch";
import ProjectTasks from "./components/ProjectTasks/ProjectTasks";
import ModalAddPointInLayer from "../../../components/ModalAddPointInLayer/ModalAddPointInLayer";
import { useHasPermissions } from "../../../hooks/useHasPermissions";
import { useNavigate } from "react-router-dom";
import { ErrorBoundary } from "../../../ui";

function Project() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { chageFitlers } = useFilter();
  const { getVisible, changeModalVisible } = useModal();

  const [viewCards, setViewCards] = useState("rows");

  const [chapter, setChapter] = useState("basicInformation");
  const [chapterChild, setChapterChild] = useState(""); // стейт для отображения columns | rows в графике
  const [viewChapterLayers, setviewChapterLayers] = useState("columns"); // columns | rows
  const [viewChapterCompanies, setviewChapterCompanies] = useState("rows"); // columns | rows
  const [viewProjectWorkSchedule, setviewProjectWorkSchedule] = useState("rows"); // columns | rows
  const [viewChapterPhoto, setVievChapterPhoto] = useState("grid"); // small-grid | grid | big-grid
  const [importFile, setImportFile] = useState(false);
  const [viewChapterParticipants, setviewChapterParticipants] = useState("rows"); // columns | rows

  const { goToPage, params } = useSearch();

  const { project } = useSelector((state) => state.projects);
  const { visible } = useHasPermissions("project", project);

  const changeWiewChapter = (value) => {
    setChapter(value);
    chageFitlers("clear");
    changeModalVisible("clear");
    setChapterChild("");
  };

  const changeViewCard = (value) => {
    setViewCards(value);
  };

  const changeChapterLayers = () => {
    setviewChapterLayers((prev) => {
      if (prev === "columns") return "rows";
      return "columns";
    });
  };

  const changeChapterParticipants = () => {
    setviewChapterParticipants((prev) => {
      if (prev === "columns") return "rows";
      return "columns";
    });
  };

  const changeChapterProjectWorkSchedule = () => {
    setviewProjectWorkSchedule((prev) => {
      if (prev === "columns") return "rows";
      return "columns";
    });
  };

  const changeChapterCompanies = () => {
    setviewChapterCompanies((prev) => {
      if (prev === "columns") return "rows";
      return "columns";
    });
  };

  const changeChapterPhoto = () => {
    setVievChapterPhoto((prev) => {
      if (prev === "big-grid") return "grid";
      if (prev === "grid") return "small-grid";
      if (prev === "small-grid") return "big-grid";
    });
  };

  const importPdf = () => {};
  const importExcel = () => {};

  const getProject = async () => {
    if (project || !params.projectId) return;

    const res = await dispatch(asyncGetProject(params.projectId));

    if (res.error) return goToPage("projects");

    dispatch(setProject(res.payload));
  };

  useEffect(() => {
    const { chapter } = params;

    if (chapter) return setChapter(chapter);
    else setChapter("basicInformation");
  }, [params.chapter]);

  useEffect(() => {
    getProject();
  }, [project]);

  return (
    <ErrorBoundary>
      <div className="project">
        <div className="project-content">
          <div className="project-content-filter">
            <LeftInfo
              onClick={() => {
                if (getVisible("infoWorkSchedule")) {
                  changeModalVisible("infoWorkSchedule", false);
                  changeWiewChapter("workSchedule");
                } else {
                  goToPage("projects");
                  dispatch(setProject(null));
                  changeModalVisible("clear");
                }
              }}
              icon
            />
            <div className="project-content-filter-set">
              <AdditionalFilterPanel
                border={!(chapter === "participants" || chapter === "meetingMinutes")}
                active={chapter}
                activeChild={chapterChild}
                currentPage={"project"}
                viewChapterCompanies={viewChapterCompanies}
                changeChapterCompanies={changeChapterCompanies}
                changeWiewChapter={changeWiewChapter}
                viewChapterLayers={viewChapterLayers}
                viewProjectWorkSchedule={viewProjectWorkSchedule}
                changeChapterLayers={changeChapterLayers}
                viewChapterParticipants={viewChapterParticipants}
                viewChapterPhoto={viewChapterPhoto}
                changeChapterParticipants={changeChapterParticipants}
                changeChapterProjectWorkSchedule={changeChapterProjectWorkSchedule}
                changeChapterPhoto={changeChapterPhoto}
                importFile={importFile}
                showImportFile={() => setImportFile((prev) => !prev)}
                importPdf={importPdf}
                importExcel={importExcel}
                viewCards={viewCards}
                onViewClick={changeViewCard}
                visibleNav={visible}
              />
              <Filter
                currentPage={`project:${chapter}`}
                disabled={
                  !(
                    chapter === "participants" ||
                    chapter === "meetingMinutes" ||
                    (chapter === "workSchedule" && !getVisible("infoWorkSchedule"))
                  )
                }
              />
            </div>
          </div>
          <div className="project-content-filter-info customScroll">
            {/* Основная информация */}
            {chapter === "basicInformation" && project && <BasicInformation />}
            {/* Участники */}
            {chapter === "participants" && project && (
              <Participants viewChapterParticipants={viewChapterParticipants} />
            )}
            {/* Компании */}
            {chapter === "companies" && project && (
              <Companies viewChapterCompanies={viewChapterCompanies} />
            )}
            {chapter === "tasks" && project && <ProjectTasks />}
            {/* Слои */}
            {chapter === "layers" && project && <Layers viewChapterLayers={viewChapterLayers} />}
            {/* Документация */}
            {chapter === "documentation" && project && (
              <DocumentationProject viewCards={viewCards} />
            )}
            {/* Протокол совещания */}
            {chapter === "protocols" && project && <MeetingMinutes />}
            {/* График производства работы */}
            {chapter === "workSchedule" && project && (
              <ProjectWorkSchedule
                viewProjectWorkSchedule={viewProjectWorkSchedule}
                editMode={"// TODO "}
                setChapterChild={setChapterChild}
                chapterChild={chapterChild}
              />
            )}
            {/* Фотоотчеты */}
            {chapter === "photoReports" && project && <PhotoReports viewCards={viewCards} />}
          </div>
        </div>
        {/* Информация о проекте */}
        {getVisible("infoProject") && <ModalProject />}
        {/* Информация о юзере */}
        {getVisible("infoUser") && <ModalInfoUser />}
        {/* Добалвение\удаление юзеров в задачи */}
        {/* Добавить рисунок или поинт */}
        {getVisible("addPoint") && <ModalAddPointInLayer />}

        {/* Просмотр рисунока */}
        {getVisible("showPoint") && <ModalAddPointInLayer show />}
        {getVisible("addEmployeesTask") && <ModalAddDellEmployees add={true} />}
        {getVisible("delEmployeesTask") && <ModalAddDellEmployees />}
      </div>
    </ErrorBoundary>
  );
}

export default Project;

import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import CompanyProfileRow from "./componets/CompanyProfileRow/CompanyProfileRow";
import "./CompanyProfiles.scss";
import SearchModal from "../../../SearchModal/SearchModal";
import Button from "../../../Button/Button";
import SearchPanel from "../../../SearchPanel/SearchPanel";
import { getFormatFIO } from "../../../../utils/getFormatFIO";
import Icons from "../../../Icons/Icons";
import { useModal } from "../../../../hooks/useModal";
import ModalAddProfile from "../../../ModalAddProfile/ModalAddProfile";
import ModalInfoEditProfiles from "../../../ModalInfoEditProfiles/ModalInfoEditProfiles";
import { useHasPermissions } from "../../../../hooks/useHasPermissions";

const CompanyProfiles = () => {
  const { getInfo, getVisible, changeModalVisible } = useModal();

  const { company } = useSelector((state) => state.companies);
  const { edit: editPermission } = useHasPermissions("companiesCard", company);

  const [profiles, setProfiles] = useState([]);
  const [textValue, setTextValue] = useState("");
  const [sort, setSort] = useState(1);

  const sorted = (arr) => {
    return arr.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return sort === 1 ? -1 : 1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return sort === 1 ? 1 : -1;
      }
      return 0;
    });
  };

  const createProfiles = () => {
    const map = {};

    for (let i = 0; i < company.profiles.length; i++) {
      const profile = company.profiles[i];

      if (map[profile.position._id]) {
        map[profile.position._id].profiles.push(profile);
      } else {
        map[profile.position._id] = { ...profile.position, profiles: [profile] };
      }
    }

    for (let i = 0; i < Object.values(map).length; i++) {
      const elem = Object.values(map)[i];
      if (elem.profiles.length > 1) {
        elem.profiles = elem.profiles.sort((a, b) => {
          if (a.user.surname < b.user.surname) {
            return -1;
          } else if (a.user.surname > b.user.surname) {
            return 1;
          }
          return 0;
        });
      }
    }

    setProfiles(sorted(Object.values(map)));
  };

  const filteredProfiles = useMemo(() => {
    const res = sorted(profiles, sort === 1 ? -1 : 1).filter((elem) => {
      if (elem?.name.toLowerCase()?.includes(textValue?.toLowerCase())) {
        return elem;
      }
      if (elem.position === null) {
        return elem;
      }
    });
    return res;
  }, [textValue, profiles, sort]);

  const changeValue = (value) => {
    setTextValue(value);
  };

  const changeSort = () => {
    setSort(sort === 1 ? -1 : 1);
  };

  useEffect(() => {
    createProfiles();
  }, [company]);

  return (
    <div className="companyProfiles">
      <SearchPanel
        onChange={changeValue}
        noHandleSearch
        // submitButton={false}
        inputSearch={{
          visible: true,
          placeholder: "Найти должность по названию",
        }}
        buttons={{
          list: editPermission && [
            {
              title: "Должность",
              onClick: () => changeModalVisible("addProfile"),
              icon: true,
            },
          ],
        }}
      />

      <ul className="companyProfiles-header">
        <li
          className="companyProfiles-header-item companyProfiles-header-item-clik"
          onClick={() => changeSort()}
        >
          <Icons type={"sort"} size={"md"} color={"blue"} />
          <span title="ФИО">Должность:</span>
        </li>
        <li className="companyProfiles-header-item">
          <span title="Должность">Сотрудники:</span>
        </li>
      </ul>

      <div className="companyProfiles-list">
        {filteredProfiles.map((profile) => {
          return <CompanyProfileRow key={profile._id} info={profile} />;
        })}
      </div>
      {/* Добавление профайла */}
      {getVisible("addProfile") && <ModalAddProfile />}
      {/* Просмотр и редактирование профайла */}
      {getVisible("infoEditProfiles") && <ModalInfoEditProfiles />}
    </div>
  );
};
export default CompanyProfiles;

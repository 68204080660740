import React, { useState, useEffect } from "react";
import "./Projects.scss";
import { useDispatch, useSelector } from "react-redux";
import { asyncDeleteProject, asyncGetProjects } from "../../../store/reducers/projectsReducer";
import AdditionalFilterPanel from "../../../components/AdditionalFilterPanel/AdditionalFilterPanel";
import Filter from "../../../components/Filter/Filter";
import LeftInfo from "../../../components/LeftInfo/LeftInfo";
import SearchPanel from "../../../components/SearchPanel/SearchPanel";
import ModalAddProject from "../../../components/ModalAddProject/ModalAddProject";
import ModalDeleteItem from "../../../components/ModalDeleteItem/ModalDeleteItem";
import ProjectsList from "../../../components/ProjectsList/ProjectsList";
import WrapperLoader from "../../../components/WrapperLoader/WrapperLoader";
import EmptySection from "../../../components/EmptySection/EmptySection";
import { useModal } from "../../../hooks/useModal";
import { useSearch } from "../../../hooks/useSearch";
import { ErrorBoundary } from "../../../ui";

function Projects() {
  const dispatch = useDispatch();

  const { projects, count, loading } = useSelector((state) => state.projects);

  const { queries } = useSearch("projects");
  const [chapter, setChapter] = useState("all"); // all | creator | member
  const [viewCards, setViewCards] = useState("rows"); // columns | rows
  const [numberPageProjects, setNumberPageProjects] = useState(1);

  const { getVisible, changeModalVisible, getInfo } = useModal();

  const deleteProject = async () => {
    await dispatch(asyncDeleteProject(getInfo("deleteProject", "data")._id));
    changeModalVisible("deleteProject", false);
    dispatch(asyncGetProjects({ page: numberPageProjects }));
  };

  const changeViewCard = (view) => setViewCards(view);

  // * проверка параметра tab
  useEffect(() => {
    if (queries.tab) setChapter(queries.tab);
  }, [queries.tab]);

  // * проверка параметра view
  useEffect(() => {
    if (queries.view) setViewCards(queries.view);
  }, [queries.view]);

  return (
    <ErrorBoundary>
      <div className="projects">
        {getVisible() && <div className="bgc-shadow"></div>}
        <div className="projects-content">
          <div className="projects-content-filter">
            <LeftInfo title={`Всего проектов: ${count || 0}`} />
            <div className="projects-content-filter-set">
              <AdditionalFilterPanel
                active={chapter}
                currentPage="projects"
                viewCards={viewCards}
                onViewClick={changeViewCard}
              />
              <Filter viewCards={viewCards} />
            </div>
          </div>
          <div className="projects-content-searchPanel">
            <SearchPanel
              inputSearch={{
                visible: true,
                placeholder: "Найти проект по названию",
              }}
              buttons={{
                list: [
                  {
                    title: "Проект",
                    onClick: () => changeModalVisible("addProject"),
                    icon: true,
                  },
                ],
              }}
            />
          </div>
          <div className="projects-content-main customScroll">
            <WrapperLoader loading={loading} chapter={"projects"} viewCards={viewCards}>
              {projects.length > 0 ? (
                <ProjectsList
                  projects={projects}
                  viewCards={viewCards}
                  changeModalVisible={changeModalVisible}
                  onChangeNumberPageProjects={(page) => setNumberPageProjects(page)}
                />
              ) : (
                !loading && (
                  <EmptySection
                    text={"Проектов не создано"}
                    // textBtn={"Добавить"}
                    // onClick={() => {
                    //   changeModalVisible("addProject", true);
                    // }}
                  />
                )
              )}
            </WrapperLoader>
          </div>
        </div>
        {/* Модальное окно добавление проекта */}
        {getVisible("addProject") && <ModalAddProject changeModalVisible={changeModalVisible} />}

        {/* Модальное окно удаление проекта */}
        {getVisible("deleteProject") && (
          <ModalDeleteItem
            heaerTitle="Удаление проекта"
            title="Вы уверены, что хотите удалить проект?"
            changeModalVisible={() => changeModalVisible("deleteProject", false)}
            onDelete={deleteProject}
          />
        )}
      </div>
    </ErrorBoundary>
  );
}

export default Projects;

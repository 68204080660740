import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./ProjectWorkSchedule.scss";
import ModalAddWorkScheldule from "../../../../../components/ModalAddWorkScheldule/ModalAddWorkScheldule";
import ModalChooseAddStepWork from "./components/ModalChooseAddStepWork/ModalChooseAddStepWork";
import ModalStage from "../../../../../components/ModalStage/ModalStage";
import ModalWork from "../../../../../components/ModalWork/ModalWork";
import ModalDeleteItem from "../../../../../components/ModalDeleteItem/ModalDeleteItem";
import { useSelects } from "../../../../../hooks/useSelects";
import SearchPanel from "../../../../../components/SearchPanel/SearchPanel";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import CardRowWorkScheduler from "./components/CardRowWorkScheduler/CardRowWorkScheduler";
import ProjectWorkScheduleItem from "./components/ProjectWorkScheduleItem/ProjectWorkScheduleItem";
import Pagination from "../../../../../components/Pagination/Pagination";
import { useDispatch } from "react-redux";
import {
  asyncDeleteScheduleProductionWorks,
  asyncDeleteStage,
  asyncDeleteWork,
  asyncGetWorksAndSteps,
} from "../../../../../store/reducers/projectsReducer";
import WrapperLoader from "../../../../../components/WrapperLoader/WrapperLoader";
import EmptySection from "../../../../../components/EmptySection/EmptySection";
import { useModal } from "../../../../../hooks/useModal";
import { useSearch } from "../../../../../hooks/useSearch";
import Button from "../../../../../components/Button/Button";
import { useHasPermissions } from "../../../../../hooks/useHasPermissions";

function ProjectWorkSchedule(props) {
  const { setChapterChild, viewProjectWorkSchedule } = props;

  const dispatch = useDispatch();

  const projects = useSelector((state) => state.projects);
  const { projectEmployees, project } = projects;

  const { changeModalVisible, getInfo, getVisible } = useModal();

  const { search, queries, setQueryParam } = useSearch("project:workSchedule");

  const { add, edit } = useHasPermissions("project:workSchedule", project);

  const { project_scheduleProductionWorks, loading } = useSelector((state) => state.projects);

  const [workScheduleItemInfo, setWorkScheduleItemInfo] = useState(null);

  const [scheduleProductionWorks, setScheduleProductionWorks] = useState([]);

  const [count, setCount] = useState(0);

  const [tasks, setTasks] = useState([]);

  const [activeSearchNav, setActiveSearchNav] = useState("customer");

  const { selectItems, addItemSelect } = useSelects(scheduleProductionWorks);

  const getWorkSchedules = () => search();

  const deleteWorkSchedules = async () => {
    let workSchedules_for_delete = [];
    if (!!selectItems.length) {
      workSchedules_for_delete = selectItems;
    } else {
      workSchedules_for_delete.push(getInfo("deleteWorkSchedule", "data")._id);
    }
    await dispatch(asyncDeleteScheduleProductionWorks(workSchedules_for_delete));
    changeModalVisible("deleteWorkSchedule", false);
    getWorkSchedules();
  };

  const getWorksAndSteps = async () => {
    const scheduleWorksId = getInfo("infoWorkSchedule", "data")?._id;
    if (!scheduleWorksId) return;
    const res = await dispatch(asyncGetWorksAndSteps(scheduleWorksId));
    if (res.error) return;
    setTasks(res.payload);
  };

  const deleteStage = async () => {
    const stage_id = getInfo("deleteStage", "data")?._id;
    const res = await dispatch(asyncDeleteStage(stage_id));
    if (res.error) return;
    await getWorksAndSteps();
    changeModalVisible("deleteStage", false, null);
  };

  const deleteWork = async () => {
    const work_id = getInfo("deleteWork", "data")?._id;
    const res = await dispatch(asyncDeleteWork(work_id));
    if (res.error) return;
    await getWorksAndSteps();
    changeModalVisible("deleteWork", false, null);
  };

  const changeActiveNavSearch = (tab) => {
    setQueryParam({ tab });
  };

  useEffect(() => {
    if (getVisible("infoWorkSchedule")) {
      return setChapterChild("infoWorkSchedule");
    }
  }, [getVisible("infoWorkSchedule")]);

  useEffect(() => {
    setScheduleProductionWorks(project_scheduleProductionWorks?.scheduleWorks || []);
    setCount(project_scheduleProductionWorks?.count || 0);
  }, [project_scheduleProductionWorks]);

  useEffect(() => {
    if (getVisible("infoWorkSchedule")) return;

    getWorkSchedules();
  }, [getVisible("infoWorkSchedule")]);

  useEffect(() => {
    getWorksAndSteps();
  }, [getInfo("infoWorkSchedule").data]);

  useEffect(() => {
    if (!queries.tab) return setActiveSearchNav("customer");

    setActiveSearchNav(queries.tab);
  }, [queries.tab]);

  useEffect(() => {
    if (!queries.work) return;

    const work = scheduleProductionWorks.find((w) => w._id === queries.work);

    if (work) setWorkScheduleItemInfo(work);
  }, [queries.edit, queries.work]);

  return (
    <div className="workSchedule">
      {getVisible(null, { without: ["infoWorkSchedule"] }) && <div className="bgc-shadow"></div>}
      <div className="workSchedule__content workSchedule-content">
        {getVisible("infoWorkSchedule") ? (
          <ProjectWorkScheduleItem
            tasks={tasks}
            viewProjectWorkSchedule={viewProjectWorkSchedule}
            data={getInfo("infoWorkSchedule")}
          />
        ) : (
          <>
            <div className="workSchedule__searchPanel workSchedule__searchPanel">
              <SearchPanel
                // navigationLeft={{
                //   visible: true,
                // }}
                inputSearch={{
                  visible: true,
                  placeholder: "Найти график по названию",
                }}
                buttons={{
                  list: add && [
                    {
                      title: "График",
                      onClick: () => changeModalVisible("addWorkScheldule", true),
                      icon: true,
                    },
                  ],
                }}
              />
            </div>
            <WrapperLoader loading={loading} chapter={"workSchedule"}>
              {scheduleProductionWorks?.length ? (
                <>
                  <div className="workSchedule-row ">
                    <ul className="workSchedule-row__header workSchedule-row-header">
                      {/* <li className="workSchedule-row-header__item numder-item">
                        <Checkbox
                          check={
                            selectItems.length ===
                            scheduleProductionWorks?.length
                          }
                          onCheck={addAllItemSelect}
                        />
                        <span>№:</span>
                      </li> */}
                      <li className="workSchedule-row-header__item item-name">
                        <span title="Название графика:">Название графика:</span>
                      </li>

                      <li className="workSchedule-row-header__item period-contract">
                        <span title="Сроки по контракту:">Согласно контракта:</span>
                      </li>
                      <li className="workSchedule-row-header__item item-period-schedule">
                        <span title="Сроки по графику:">Согласно графика:</span>
                      </li>
                      {/* <li className="workSchedule-row-header__item item-director">
                        <span title="Руководитель:">Ген.подрядчик: </span>
                      </li> */}

                      <li className="workSchedule-row-header__item item-overdue">
                        <span title="Отклонение по срокам:">Отклонение по срокам:</span>
                      </li>
                    </ul>
                    <ul className="workSchedule-row__cards workSchedule-row-cards">
                      {scheduleProductionWorks?.map((elem) => {
                        return (
                          <CardRowWorkScheduler
                            addItemSelect={addItemSelect}
                            changeModalVisible={changeModalVisible}
                            key={elem._id}
                            info={elem}
                            check={selectItems.includes(elem._id)}
                            edit={edit}
                          />
                        );
                      })}
                    </ul>
                    <Pagination count={count} />
                  </div>
                </>
              ) : (
                <EmptySection
                  text={"В данном проекте ещё нет графиков"}
                  textBtn={"Добавить"}
                  onClick={() => {
                    changeModalVisible("addWorkScheldule", true);
                  }}
                />
              )}
            </WrapperLoader>
          </>
        )}
      </div>
      {/* Модальное окно просмотра/редактирование/создания Графика */}
      {getVisible("addWorkScheldule") && (
        <ModalAddWorkScheldule
          handleChange={getWorkSchedules}
          access={activeSearchNav}
          changeModalVisible={changeModalVisible}
        />
      )}
      {/* Модальное окно выбора создания Этапа или Раборты */}
      {getVisible("chooseStageOrWork") && <ModalChooseAddStepWork />}
      {/* Модальное окно просмотра/редактирование/создания Этапа */}
      {getVisible("infoStage") && (
        <ModalStage
          info={getInfo("infoStage", "data")}
          changeModalVisible={changeModalVisible}
          scheduleWorksId={getInfo("infoWorkSchedule", "data")?._id}
          getWorksAndSteps={getWorksAndSteps}
          editMode={getInfo("infoStage", "editMode")}
          createMode={getInfo("infoStage", "createMode")}
        />
      )}
      {/* Модальное окно просмотра/редактирование/создания Работы */}
      {getVisible("infoWork") && (
        <ModalWork
          info={getInfo("infoWork", "data")}
          changeModalVisible={changeModalVisible}
          scheduleWorksId={getInfo("infoWorkSchedule", "data")?._id}
          getWorksAndSteps={getWorksAndSteps}
          editMode={getInfo("infoWork", "editMode")}
          createMode={getInfo("infoWork", "createMode")}
        />
      )}
      {/* Модальное окно для удаление этапа  */}
      {getVisible("deleteStage") && (
        <ModalDeleteItem
          title="Вы уверены, что хотите удалить этот этап?"
          headerTitle="Удаление этапа"
          nameModal="deleteStage"
          changeModalVisible={changeModalVisible}
          onDelete={deleteStage}
        />
      )}
      {/* Модальное окно для удаление работа  */}
      {getVisible("deleteWork") && (
        <ModalDeleteItem
          title="Вы уверены, что хотите удалить эту работу?"
          headerTitle="Удаление работы"
          nameModal="deleteWork"
          changeModalVisible={changeModalVisible}
          onDelete={deleteWork}
        />
      )}
      {/* Модальное окно для удаления графика  */}
      {getVisible("deleteWorkSchedule") && (
        <ModalDeleteItem
          title="Вы уверены, что хотите удалить этот график?"
          headerTitle="Удаление графика производства"
          nameModal="deleteWorkSchedule"
          changeModalVisible={changeModalVisible}
          onDelete={deleteWorkSchedules}
        />
      )}
    </div>
  );
}

export default ProjectWorkSchedule;

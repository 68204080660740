import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./AddedCatalogWork.scss";
import { useModal } from "../../../../hooks/useModal";
import SearchPanel from "../../../SearchPanel/SearchPanel";
import {
  getCatalogWork,
  asyncDeleteCatalogWork,
} from "../../../../store/reducers/catalogWorkReducer";
import SettingElementCard from "../../../SettingElementCard/SettingElementCard";
import Button from "../../../Button/Button";
import ModalDeleteItem from "../../../ModalDeleteItem/ModalDeleteItem";
import ModallAddEditCatalogWork from "./components/ModallAddEditCatalogWork/ModallAddEditCatalogWork";
import Screen from "../../../Screen/Screen";
import { useSearch } from "../../../../hooks/useSearch";

function sortByName(array) {
  let arr = [...array];
  return arr?.sort((a, b) => {
    if (a?.name < b?.name) return -1;
    if (a?.name > b?.name) return 1;
    return 0;
  });
}
const AddedCatalogWork = () => {
  const dispatch = useDispatch();

  const { changeModalVisible, getVisible, getInfo } = useModal();

  const [catalogWorks, setCatalogWorks] = useState([]);

  const { search } = useSearch("settings:project");

  const getCatalog = () => search();

  const { catalogWork } = useSelector((state) => state.catalogWork);

  const deleteCatalogWork = async () => {
    const { _id: catalogWorkId } = getInfo("deleteCatalogWork", "data");
    await dispatch(asyncDeleteCatalogWork(catalogWorkId));

    getCatalog();
    changeModalVisible("deleteCatalogWork", false, null);
  };

  useEffect(() => {
    setCatalogWorks(sortByName(catalogWork));
  }, [catalogWork]);

  return (
    <div className="addedCatalogWork">
      {getVisible() && <div className="bgc-shadow"></div>}
      <h2 className="addedCatalogWork-title">Добавление наименований работ в библиотеку:</h2>
      <div className="addedCatalogWork-searchPanel">
        <Screen size={"lg"}>
          <SearchPanel
            inputSearch={{
              visible: true,
              placeholder: "Найти работу названию",
            }}
            buttons={{
              list: [
                {
                  title: "Работу",
                  icon: true,
                  onClick: () => changeModalVisible("addCatalogWork"),
                },
              ],
            }}
          />
        </Screen>
        <Screen size={"lg"} down>
          <SearchPanel
            inputSearch={{
              visible: true,
              placeholder: "Найти работу названию",
            }}
          />
        </Screen>
      </div>
      <div className="addedCatalogWork-content customScroll">
        {!!catalogWorks.length ? (
          <div className="addedCatalogWork-grid ">
            {catalogWorks.map((work) => (
              <SettingElementCard
                info={work}
                key={work._id}
                onDelete={() => changeModalVisible("deleteCatalogWork", true, work)}
                edit={true}
                onEdit={() => changeModalVisible("editCatalogWork", true, work)}
              />
            ))}
          </div>
        ) : (
          <div className="addedCatalogWork-empty">
            <h2 className="addedCatalogWork-empty-title">По данному запросу работ не найдено</h2>
            <Button
              title="Добавить"
              color="blue"
              onClick={() => changeModalVisible("addCatalogWork")}
            />
          </div>
        )}
      </div>
      {getVisible("deleteCatalogWork") && (
        <ModalDeleteItem
          changeModalVisible={() => changeModalVisible("deleteCatalogWork", false)}
          onDelete={deleteCatalogWork}
          title="Вы уверены, что хотите удалить работу?"
          headerTitle="Удалить работу"
        />
      )}
      {getVisible("addCatalogWork") && <ModallAddEditCatalogWork handleChange={getCatalog} />}
      {getVisible("editCatalogWork") && (
        <ModallAddEditCatalogWork handleChange={getCatalog} edit={true} />
      )}
    </div>
  );
};

export default AddedCatalogWork;

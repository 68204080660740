import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./AddedPosition.scss";
import SearchPanel from "../../../SearchPanel/SearchPanel";
import { getPosition, asyncDeletePosition } from "../../../../store/reducers/positionsReducer";
import { useModal } from "../../../../hooks/useModal";
import ModallAddPosition from "../../../ModallAddPosition/ModallAddPosition";
import ModalDeleteItem from "../../../ModalDeleteItem/ModalDeleteItem";
import { useSelects } from "../../../../hooks/useSelects";
import Button from "../../../Button/Button";
import SettingElementCard from "../../../SettingElementCard/SettingElementCard";
import Screen from "../../../Screen/Screen";
import { useSearch } from "../../../../hooks/useSearch";

const filterPositions = (positions) => {
  const positionsProtection = [];
  const positionsNonProtection = [];

  for (const position of positions) {
    if (position.protection) {
      positionsProtection.push(position);
    } else {
      positionsNonProtection.push(position);
    }
  }

  return [...positionsProtection, ...positionsNonProtection];
};

const AddedPosition = () => {
  const dispatch = useDispatch();
  const { positions } = useSelector((state) => state.positions);
  const [positionsList, setPositonsList] = useState([]);
  const [query, setQuery] = useState("");
  const { changeModalVisible, getVisible, getInfo } = useModal();

  const { search } = useSearch("settings:employees");

  const getPositions = () => search();

  const handelChange = (str) => {
    setQuery(str);
  };

  const filtredArray = (positions, query) => {
    const filteredArray = [];
    for (const item of positions) {
      if (item.name.toLowerCase().includes(query.toLowerCase())) {
        filteredArray.push(item);
      }
    }
    return filteredArray;
  };

  const deletePosition = async () => {
    const { _id: positionId } = getInfo("deletePosition", "data");
    await dispatch(asyncDeletePosition(positionId));

    getPositions();
    changeModalVisible("deletePosition", false, null);
  };

  useEffect(() => setPositonsList(filterPositions(positions)), [positions]);

  return (
    <div className="addedPosition">
      {getVisible() && <div className="bgc-shadow"></div>}
      <h2 className="addedPosition-title">Добавление должности:</h2>
      <div className="addedPosition-searchPanel">
        <Screen size={"lg"}>
          <SearchPanel
            inputSearch={{
              visible: true,
              placeholder: "Найти должность",
            }}
            onChange={handelChange}
            buttons={{
              list: [
                {
                  title: "Должность",
                  icon: true,
                  onClick: () => changeModalVisible("addPosition"),
                },
              ],
            }}
          />
        </Screen>
        <Screen size={"lg"} down>
          <SearchPanel
            inputSearch={{
              visible: true,
              placeholder: "Найти должность",
            }}
          />
        </Screen>
      </div>
      <div className="addedPosition-content customScroll">
        {!!positionsList.length ? (
          <div className="addedPosition-grid ">
            {filtredArray(positionsList, query).map((position) => (
              <SettingElementCard
                info={position}
                key={position._id}
                onDelete={() => changeModalVisible("deletePosition", true, position)}
              />
            ))}
          </div>
        ) : (
          <div className="addedPosition-empty">
            <h2 className="addedPosition-empty-title">По данному запросу позиций не найдено</h2>
            <Button
              title="Добавить"
              color="blue"
              onClick={() => changeModalVisible("addPosition")}
            />
          </div>
        )}
      </div>
      {/* Добавление позиции */}
      {getVisible("addPosition") && <ModallAddPosition handleChange={getPositions} />}
      {/* Удалить задачу */}
      {getVisible("deletePosition") && (
        <ModalDeleteItem
          changeModalVisible={() => changeModalVisible("deletePosition", false)}
          onDelete={deletePosition}
          title="Вы уверены, что хотите удалить позицию?"
          headerTitle="Удалить позицию"
        />
      )}
    </div>
  );
};

export default AddedPosition;

import React, { useEffect, useMemo, useState } from "react";
import "./CompanyResponsibles.scss";
import { useDispatch } from "react-redux";
import {
  asyncEditResponsibles,
  asyncGetResponsibles,
} from "../../../../store/reducers/companiesReducer";
import { useParams } from "react-router-dom";
import SearchModal from "../../../SearchModal/SearchModal";
import CompanyResponsibleRow from "./components/CompanyResponsibleRow/CompanyResponsibleRow";
import { getFormatFIO } from "../../../../utils/getFormatFIO";
import SearchPanel from "../../../SearchPanel/SearchPanel";
import { useModal } from "../../../../hooks/useModal";
import ModalAddEditResponible from "../../../ModalAddEditResponible/ModalAddEditResponible";
import ModalDeleteItem from "../../../ModalDeleteItem/ModalDeleteItem";
import WrapperLoader from "../../../WrapperLoader/WrapperLoader";
import Icons from "../../../Icons/Icons";
import { useHasPermissions } from "../../../../hooks/useHasPermissions";
import { useSelector } from "react-redux";

const CompanyResponsibles = () => {
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.companies);
  const { edit: editPermission } = useHasPermissions("companiesCard", company);

  const { getInfo, getVisible, changeModalVisible } = useModal();

  const [loading, setLoading] = useState(false);

  const { companyId } = useParams();
  const [textValue, setTextValue] = useState("");

  const [responsibles, setResponsibles] = useState([]);

  const [sort, setSort] = useState({ name: "project", value: 1 });

  const sorted = (type, arr, sort) => {
    if (type === "project") {
      return (
        arr.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return sort === 1 ? -1 : 1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return sort === 1 ? 1 : -1;
          }
          return 0;
        }) || []
      );
    }
    if (type === "user") {
      return (
        arr.sort((a, b) => {
          if (getFormatFIO(a.responsible.user) < getFormatFIO(b.responsible.user)) {
            return sort === 1 ? -1 : 1;
          }
          if (getFormatFIO(a.responsible.user) > getFormatFIO(b.responsible.user)) {
            return sort === 1 ? 1 : -1;
          }
          return 0;
        }) || []
      );
    }
  };

  const removeResponsible = async () => {
    const responsible = getInfo("deleteResponible", "data");

    if (!responsible) return;
    setLoading(true);
    const res = await dispatch(
      asyncEditResponsibles({
        companyId: companyId,
        data: {
          event: "remove",
          data: {
            project: responsible._id,
            user: responsible.responsible.user._id,
          },
        },
      }),
    );
    setLoading(false);
    if (res.error) return;

    setResponsibles((prev) => prev.filter((r) => r._id !== responsible._id));
    changeModalVisible("deleteResponible", false);
  };

  const filteredResponsibles = useMemo(() => {
    const res = sorted(sort.name, responsibles, sort.value === 1 ? -1 : 1)?.filter((elem) => {
      if (
        elem?.name.toLowerCase()?.includes(textValue?.toLowerCase()) ||
        getFormatFIO(elem.responsible.user, "full")
          .toLowerCase()
          ?.includes(textValue?.toLowerCase())
      ) {
        return elem;
      }
    });
    return res;
  }, [textValue, responsibles, sort]);

  const getResponsibles = async () => {
    if (!companyId) return;
    setLoading(true);
    const res = await dispatch(asyncGetResponsibles({ companyId: companyId }));
    setLoading(false);
    if (res.error) return;

    const sortArr = sorted("project", res.payload, 1);

    setResponsibles(sortArr);
  };

  const changeValue = (value) => {
    setTextValue(value);
  };

  const changeSort = (value) => {
    setSort({ name: value, value: sort.value === 1 ? -1 : 1 });
  };

  useEffect(() => {
    getResponsibles();
  }, [companyId]);

  return (
    <div className="companyResponsibles">
      <SearchPanel
        onChange={changeValue}
        noHandleSearch
        submitButton={false}
        inputSearch={{
          visible: true,
          placeholder: "Найти проект по названию или ответсвенного по ФИО.",
        }}
        buttons={{
          list: editPermission && [
            {
              title: "Ответственного",
              onClick: () => changeModalVisible("addEditResponible"),
              icon: true,
            },
          ],
        }}
      />

      <ul className="companyResponsibles-header">
        <li className="companyResponsibles-header-item" onClick={() => changeSort("project")}>
          <Icons type={"sort"} size={"md"} color={"blue"} />
          <span title="ФИО">Проект:</span>
        </li>
        <li className="companyResponsibles-header-item" onClick={() => changeSort("user")}>
          <Icons type={"sort"} size={"md"} color={"blue"} />
          <span title="Должность">Сотрудник:</span>
        </li>
      </ul>
      <WrapperLoader loading={loading}>
        <div className="companyResponsibles-list">
          {filteredResponsibles.map((responsible) => {
            return <CompanyResponsibleRow key={responsible._id} info={responsible} />;
          })}
        </div>
      </WrapperLoader>
      {/* Добавление и редактирование ответсвенного */}
      {getVisible("addEditResponible") && <ModalAddEditResponible getData={getResponsibles} />}
      {/* Удалить сотрудника */}
      {getVisible("deleteResponible") && (
        <ModalDeleteItem
          changeModalVisible={() => changeModalVisible("deleteResponible", false)}
          title="Вы уверены, что хотите удалить ответсвенного?"
          headerTitle="Удалить ответсвенного"
          onDelete={removeResponsible}
        />
      )}
    </div>
  );
};

export default CompanyResponsibles;

import React, { useState, useEffect } from "react";
import "./Reference.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetReferences,
  asyncDeleteReferences,
} from "../../../store/reducers/referencesReducer";
import LeftInfo from "../../../components/LeftInfo/LeftInfo";
import SearchPanel from "../../../components/SearchPanel/SearchPanel";
import ReferenceList from "../../../components/ReferenceList/ReferenceList";
import WrapperLoader from "../../../components/WrapperLoader/WrapperLoader";
import ModalAddEditReference from "../../../components/ModalAddEditReference/ModalAddEditReference";
import { useModal } from "../../../hooks/useModal";
import ModalDeleteItem from "../../../components/ModalDeleteItem/ModalDeleteItem";
import { useNavigate, useParams } from "react-router-dom";
import EmptySection from "../../../components/EmptySection/EmptySection";
import { useSearch } from "../../../hooks/useSearch";
import { useHasPermissions } from "../../../hooks/useHasPermissions";
import { ErrorBoundary } from "../../../ui";

const Reference = () => {
  const dispatch = useDispatch();

  const { add } = useHasPermissions("reference");
  const { changeModalVisible, getVisible, getInfo } = useModal();
  const { references, count, loading } = useSelector((state) => state.references);

  const { search } = useSearch("reference");

  const deleteReference = async () => {
    const { _id } = getInfo("deleteReference", "data");
    let delReference = [];
    delReference.push(getInfo("deleteReference", "data")._id);
    if (_id) {
      await dispatch(asyncDeleteReferences(delReference));
      await search();
    }
    changeModalVisible("deleteReference", false);
  };

  // useEffect(() => {
  //   jivo_init();
  // }, []);

  return (
    <ErrorBoundary>
      <div className="reference">
        {getVisible() && <div className="bgc-shadow"></div>}
        <div className="reference-content">
          <div className="reference-content__filter">
            <LeftInfo title={`Всего справок: ${count}`} />
          </div>
          <div className="reference-content__searchPanel reference__searchPanel">
            <SearchPanel
              inputSearch={{
                visible: true,
                placeholder: "Найти справку по названию",
              }}
              buttons={{
                list: add && [
                  {
                    title: "Справка",
                    onClick: () => changeModalVisible("addReference"),
                    icon: true,
                  },
                ],
              }}
            />
          </div>
          <div className="reference-content-wrapper  customScroll">
            <WrapperLoader loading={loading}>
              {!!count ? (
                <ReferenceList references={references} count={count} />
              ) : (
                <EmptySection
                  text={"Справок не найдено"}
                  textBtn={"Добавить"}
                  onClick={() => {
                    changeModalVisible("addReference", true);
                  }}
                />
              )}
            </WrapperLoader>
          </div>
        </div>
        {/* Добавление позиции */}
        {getVisible("addReference") && <ModalAddEditReference handleChange={search} />}
        {/* Добавление позиции */}
        {getVisible("editReference") && <ModalAddEditReference handleChange={search} edit={true} />}
        {getVisible("deleteReference") && (
          <ModalDeleteItem
            changeModalVisible={() => changeModalVisible("deleteReference", false)}
            onDelete={deleteReference}
            title="Вы уверены, что хотите удалить справку?"
            headerTitle="Удалить справку"
          />
        )}
      </div>
    </ErrorBoundary>
  );
};

export default Reference;

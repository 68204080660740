import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import "./CompanyEmployeesList.scss";
import Icons from "../../../Icons/Icons";
import { useSearch } from "../../../../hooks/useSearch";
import SearchPanel from "../../../SearchPanel/SearchPanel";
import EmployeesListRow from "../../../EmployeesListRow/EmployeesListRow";
import Pagination from "../../../Pagination/Pagination";
import WrapperLoader from "../../../WrapperLoader/WrapperLoader";
import { useParams } from "react-router-dom";
import ModalEditEmployee from "../../../ModalEditEmployee/ModalEditEmployee";
import { useModal } from "../../../../hooks/useModal";
import ModalAddEmployee from "../../../ModalAddEmployee/ModalAddEmployee";
import ModalInfoUser from "../../../ModalInfoUser/ModalInfoUser";
import ModalProject from "../../../ModalProject/ModalProject";
import { useHasPermissions } from "../../../../hooks/useHasPermissions";

const CompanyEmployeesList = () => {
  const { company } = useSelector((state) => state.companies);

  const { edit: editPermission } = useHasPermissions("companiesCard", company);

  const { getInfo, getVisible, changeModalVisible } = useModal();

  const { companyId } = useParams();

  const { params } = useSearch("company:users");

  const { users, count, loading } = useSelector((state) => state.user);

  const [employees, setEmployees] = useState([]);

  const updtateEmployees = () => {
    const updateUsers = users.map((user) => {
      const filterProfiles = [];
      for (let i = 0; i < user.profiles.length; i++) {
        const profile = user.profiles[i];
        if (profile.company._id === company._id) {
          filterProfiles.push(profile);
        }
      }
      return { ...user, profiles: filterProfiles };
    });
    setEmployees(updateUsers);
  };

  const openModalInfo = (info) => {
    changeModalVisible("infoUser", true, info);
  };

  useEffect(() => {
    updtateEmployees();
  }, [users, companyId]);

  return (
    <div className="companyEmployeesList">
      <div className="companyEmployeesList-search">
        <SearchPanel
          inputSearch={{
            visible: true,
            placeholder: "Найти сотрудника по ФИО",
          }}
          buttons={{
            list: editPermission && [
              {
                title: "Сотрудник",
                icon: true,
                onClick: () => {
                  changeModalVisible("addEmployee", true);
                },
              },
            ],
          }}
        />
      </div>
      <ul className="companyEmployeesList-header">
        <li className="companyEmployeesList-header-item">
          {/* <Icons type={"sort"} size={"md"} color={"blue"} /> */}
          <span title="ФИО">ФИО:</span>
        </li>
        <li className="companyEmployeesList-header-item">
          <span title="Должность">Должность:</span>
        </li>
        <li className="companyEmployeesList-header-item">
          <span title="Email">Email:</span>
        </li>
        <li className="companyEmployeesList-header-item">
          <span title="Телефон">Телефон:</span>
        </li>

        <li className="companyEmployeesList-header-item">
          <span title="Задачи">Задачи:</span>
        </li>
      </ul>
      <ul className="employeesList-row-list">
        <WrapperLoader loading={loading}>
          {employees.map((employee, index) => (
            <EmployeesListRow
              key={employee._id}
              info={employee}
              employees={users}
              type="company"
              onInfo={openModalInfo}
            />
          ))}
          <Pagination count={count} />
        </WrapperLoader>
      </ul>
      {/* Редактирование сотрудника */}
      {getVisible("editEmployee") && <ModalEditEmployee />}
      {/* Добавление сотрудника */}
      {getVisible("addEmployee") && <ModalAddEmployee />}
      {getVisible("infoUser") && <ModalInfoUser />}
      {getVisible("infoProject") && <ModalProject />}
    </div>
  );
};

export default CompanyEmployeesList;
